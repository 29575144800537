const COLLAB_PATH = 'ccollab'; // the extra c is for Confluence

export const getCollabProviderUrl = () => {
	if (window.location.hostname === 'localhost') {
		// In dev mode this allows the local dev proxy to use the WebSocket host
		// that matches the backend origin host. The __backendOriginHost is only
		// available with local dev server.
		const host = (window as any).__backendOriginHost;

		return `https://${host}/${COLLAB_PATH}`;
	}
	return `${window.location.origin}/${COLLAB_PATH}`;
};
